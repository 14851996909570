<template>
  <div v-page-access="permissions.topupView">
    <CRow>
      <CCol id="lhs">
        <div class="pointer-on-hover" @click="$router.go(-1)">
          <BackButton />
        </div>
        <br />
        <balance-card :hasButtonTopup="false" />
        <CCard>
          <CCardBody>
            <CRow>
              <CCol>
                <h5 class="mb-4">{{ $t("DISBURSEMENTS.LABELS.TOPUP_BALANCE") }}</h5>
                <CInput
                  :label="getAmountLabel()"
                  placeholder="Amount"
                  class="col-12 px-0 mb-3"
                  type="number"
                  aria-valuemin="0"
                  v-model="amount"
                  :is-valid="amount !== null && !$store.getters.getIsSandboxActive? amount >= minimumTopup : null"
                  :invalid-feedback="
                    $t(
                      'DISBURSEMENTS.VALIDATION_ERRORS.TOP_UP_AMOUNT_VALIDATION'
                    )
                  "
                ></CInput>
                <CSelect id="banks" :options="getBankOptions()" class="col-12 px-0" />
              </CCol>
            </CRow>
            <CRow class="mt-4 mb-2">
              <CCol>
                <CButton
                  class="px-2 float-right"
                  color="durianprimary"
                  :disabled="amount === null || amount < minimumTopup || $store.getters.getIsSandboxActive"
                  @click="getTopupInstruction()"
                >{{ $t("DISBURSEMENTS.LABELS.INSTRUCTIONS") }}</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <br />
        <br />
        <CCard>
          <CCardHeader class="mt-2">
            <CRow>
              <CCol md="6">
                <h5>{{ $t("DISBURSEMENTS.LABELS.TITLE") }}</h5>
              </CCol>
              <CCol md="6">
                <ValidationProvider name="Topup Date">
                  <date-picker
                    class="primary col-12 col-xl-12"
                    v-model="dates"
                    type="date"
                    range
                    placeholder="Select date range"
                    @change="selectRange"
                  ></date-picker>
                </ValidationProvider>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              class="col-md-12 p-0 pointer-on-hover"
              hover
              :items="topups"
              :fields="fields"
              @row-clicked="getTopUpInstructionsByID"
            >
              <template #name="data">
                <td>{{ data.item.bank_name }}</td>
              </template>
              <template #created_at="data">
                <td>{{ formatDate(data.item.created_at) }}</td>
              </template>
              <template #amount="data">
                <td>Rp {{ formatAmount(data.item.amount) }}</td>
              </template>
              <template #status="data">
                <td>
                  <CBadge
                    :color="getTopUpHistoryBadge(data.item.status)"
                    class="pointer-on-hover"
                  >{{ data.item.status }}</CBadge>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <b-pagination
            size="md"
            align="center"
            @change="pageChange"
            :per-page="limit"
            :total-rows="totalRows"
            v-model="pageNumber"
          ></b-pagination>
        </CCard>
      </CCol>
    </CRow>
    <top-up-instructions-modal
      :isModalOpen="isInstructionModalOpen"
      :toggleModal="toggleInstructionsModal"
      :instructions="instructions"
    />
  </div>
</template>

<script>
import BalanceCard from "./components/BalanceCard.vue";
import {
  getTopUpHistory,
  getTopUpBanks,
  getTopupInstructions,
  getTopUpInstructionsByID
} from "@/api/disbursement.api.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import TopUpInstructionsModal from "./components/modals/TopUpInstructionsModal.vue";
import BackButton from "./components/BackButton.vue";
import { sandboxEventEmitter } from "@/../config.js";

const PROCESSING_STATUS = "processing";
const COMPONENT_NAME = "TopupDisbursements";
export default {
  components: { BalanceCard, DatePicker, TopUpInstructionsModal, BackButton },
  name: COMPONENT_NAME,
  data() {
    return {
      minimumTopup: 10000,
      isInstructionModalOpen: false,
      amount: null,
      bankID: null,
      isInstructionsButtonClicked: false,
      limit: 10,
      pageNumber: 1,
      totalRows: 0,
      disbursementTopUpHistoryFilters: {
        from: "",
        to: ""
      },
      fields: [
        { key: "name", label: this.$t("DISBURSEMENTS.LABELS.BANK_NAME") },
        {
          key: "created_at",
          label: this.$t("DISBURSEMENTS.LABELS.TOPUP_DATE")
        },
        {
          key: "amount",
          label: this.$t("DISBURSEMENTS.LABELS.TOPUP_AMOUNT")
        },
        {
          key: "status",
          label: this.$t("DISBURSEMENTS.LABELS.STATUS")
        }
      ],
      range: {
        start: new Date(2020, 0, 1),
        end: new Date()
      },
      bankList: null,
      topups: [],
      instructions: {
        bankName: "",
        expiry: "",
        bankAccountNumber: "",
        bankHolderName: "",
        uniqueCode: "",
        totalAmount: ""
      },
      dates: []
    };
  },
  methods: {
    getAmountLabel() {
      return this.$t("DISBURSEMENTS.LABELS.AMOUNT");
    },
    getBankLabel() {
      return this.$t("DISBURSEMENTS.LABELS.BANK");
    },
    getInstructionTitle() {
      return this.$t("DISBURSEMENTS.TOPUP_INSTRUCTIONS.TITLE");
    },
    toggleInstructionsModal() {
      this.isInstructionModalOpen = !this.isInstructionModalOpen;
    },
    selectRange() {
      if (this.ifValid(this.dates[0]) && this.ifValid(this.dates[1])) {
        this.disbursementTopUpHistoryFilters.from = this.moment(this.dates[0])
          .format("YYYY-MM-DD")
          .toString();
        this.disbursementTopUpHistoryFilters.to = this.moment(this.dates[1])
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.disbursementTopUpHistoryFilters.from = this.range.start;
        this.disbursementTopUpHistoryFilters.to = this.range.end;
      }
      this.getTopUpHistory();
    },
    pageChange(val) {
      this.skip = (val - 1) * this.limit;
      this.getTopUpHistory();
    },
    async getTopUpHistory() {
      try {
        const payload = {
          from: this.disbursementTopUpHistoryFilters.from,
          to: this.disbursementTopUpHistoryFilters.to,
          skip: this.skip,
          limit: this.limit
        };
        const response = await getTopUpHistory(payload);
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data;
          this.topups = data.topups;
          this.totalRows = data.count;
        } else {
          this.showToaster(
            this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_TOPUP")
          );
        }
      } catch (error) {
        this.showToaster(this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_TOPUP"));
      }
    },
    async getTopUpInstructionsByID(item, _) {
      if (item["status"] === PROCESSING_STATUS) {
        try {
          const response = await getTopUpInstructionsByID(item["id"]);
          const err = this.verifyHttpResponse(response);
          if (err) {
            this.showToaster(
              this.$t("DISBURSEMENTS.ERRROS.ERROR_FETCHING_TOPUP_INSTRUCTIONS")
            );
            return;
          }
          const data = response.data.data;
          this.instructions.bankName = data.bank_name;
          this.instructions.expiry = data.expiry_date;
          this.instructions.bankAccountNumber = data.bank_account_number;
          this.instructions.bankHolderName = data.bank_holder_name;
          this.instructions.totalAmount = data.total_amount;
          this.instructions.uniqueCode = data.unique_code;
          this.toggleInstructionsModal();
        } catch (error) {
          this.showToaster(
            this.$t("DISBURSEMENTS.ERRROS.ERROR_FETCHING_TOPUP_INSTRUCTIONS")
          );
        }
      }
    },
    getBankOptions() {
      const options = [];
      for (let key in this.bankList) {
        const { name, id } = this.bankList[key];
        options.push({ value: id, label: name });
      }
      return options;
    },
    validateInstructionsPayload(payload) {
      return payload.amount >= this.minimumTopup;
    },
    async getBankList() {
      try {
        const payload = {
          type: "topup"
        };
        const response = await getTopUpBanks(payload);
        this.bankList = response.data.data;
      } catch (error) {
        this.showToaster(
          this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_BANK_DATA")
        );
      }
    },
    getBankIdFromSelect() {
      const element = document.getElementById("banks");
      const selectedIndex = element.options.selectedIndex;
      return element.options[selectedIndex].value;
    },
    async getTopupInstruction() {
      try {
        const id = this.getBankIdFromSelect();
        const payload = {
          amount: this.amount.toString(),
          bank_id: parseInt(id, 10)
        };
        if (!this.validateInstructionsPayload(payload)) {
          this.showToaster(
            this.$t("DISBURSEMENTS.VALIDATION_ERRORS.TOP_UP_AMOUNT_VALIDATION")
          );
          return;
        }
        const response = await getTopupInstructions(payload);
        const data = response.data.data;
        this.instructions.bankName = data.transfer_to.bank_name;
        this.instructions.expiry = data.expiry_date;
        this.instructions.bankAccountNumber =
          data.transfer_to.bank_account_number;
        this.instructions.bankHolderName = data.transfer_to.bank_holder_name;
        this.instructions.totalAmount = data.total_amount;
        this.instructions.uniqueCode = data.transfer_to.unique_code;
        this.toggleInstructionsModal();
      } catch (error) {
        this.$t("DISBURSEMENTS.ERRORS.ERROR_FETCHING_TOPUP_INSTRUCTIONS");
      }
    },
    getTopUpHistoryBadge(status) {
      switch (status) {
        case "done":
          return "success";
        case "processing":
          return "warning";
        case "failed":
          return "danger";
        default:
          return "primary";
      }
    }
  },
  mounted() {
    this.getTopUpHistory();
    this.getBankList();
  },
  created() {
    this.$eventHub.$on(
      sandboxEventEmitter.prefix + COMPONENT_NAME,
      this.getTopUpHistory
    );
  },
  beforeDestroy() {
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  }
};
</script>
<style>
#filter-date {
  max-width: 60% !important;
}
#lhs {
  max-width: 40% !important;
}
.info {
  background: #fdfae7;
  border: 1px solid #fbf5ce;
}
.greybox {
  background-color: #f9f9f9;
}
</style>

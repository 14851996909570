<template>
  <CModal :show="isModalOpen" size="lg">
    <div slot="header">
      <h5 class="mb-0 pb-0">
        <strong>{{ $t("DISBURSEMENTS.TOPUP_INSTRUCTIONS.TITLE") }}</strong>
      </h5>
    </div>
    <CAlert class="p-2 info">
      <i18n path="DISBURSEMENTS.TOPUP_INSTRUCTIONS.ALERT" tag="span" for="tos">
        <strong>{{ formatDate(instructions.expiry) }}</strong>
      </i18n>
    </CAlert>
    <p>{{ $t("DISBURSEMENTS.TOPUP_INSTRUCTIONS.ACCOUNT_INFO") }}</p>
    <div class="p-3 mb-3" style="background: #f9f9f9">
      <div class="d-flex flex-row">
        <img
          src="https://via.placeholder.com/75x50"
          class="align-self-center"
        />
        <div class="align-self-center ml-4">
          <span>{{ instructions.bankName }}</span>
          <br />
          <span>{{ instructions.bankHolderName }}</span>
        </div>
      </div>
      <hr />
      <div class="d-flex flex-row justify-content-between greybox">
        <strong class="h3 align-self-center">{{
          instructions.bankAccountNumber
        }}</strong>
        <div class="align-self-center">
          <CButton
            @click.prevent="
              copyToClipboard(
                instructions.bankAccountNumber,
                'Account number copied'
              )
            "
          >
            <CIcon name="cil-copy" class="mr-2" />
            <strong class="text-info">{{ $t("COMMON.COPY_BUTTON") }}</strong>
          </CButton>
        </div>
      </div>
    </div>
    <p>{{ $t("DISBURSEMENTS.TOPUP_INSTRUCTIONS.AMOUNT_INFO") }}</p>
    <div class="p-3 d-flex flex-row justify-content-between greybox">
      <strong class="h3 align-self-center"
        >Rp {{ formatAmount(instructions.totalAmount) }}</strong
      >
      <div class="align-self-center">
        <CButton
          @click.prevent="
            copyToClipboard(instructions.totalAmount, 'Amount copied')
          "
        >
          <CIcon name="cil-copy" class="mr-2" />
          <strong class="text-info">{{ $t("COMMON.COPY_BUTTON") }}</strong>
        </CButton>
      </div>
    </div>
    <ul class="mt-2">
      <li>
        <i18n
          path="DISBURSEMENTS.TOPUP_INSTRUCTIONS.FOOTER_INFO"
          tag="span"
          for="tos"
        >
          <strong>{{ formatAmount(instructions.totalAmount) }}</strong>
        </i18n>
      </li>
      <li>
        <i18n
          path="DISBURSEMENTS.TOPUP_INSTRUCTIONS.FOOTER_SECOND_INFO"
          tag="span"
          for="tos"
        >
          <strong>{{ instructions.uniqueCode }}</strong>
        </i18n>
      </li>
    </ul>
    <div slot="footer">
      <CButton @click="toggleModal()" color="durianprimary">Close </CButton>
    </div>
  </CModal>
</template>

<script>
export default {
  name: "InstructionsModal",
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    instructions: Object,
  },
};
</script>